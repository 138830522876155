// src/component/avatar/signup-login/signup-login.js
import React, { useState, useEffect } from "react";
import { faGoogle, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SignupLogin.css";
import Ripples from "react-ripples";
import { preloginState, userState } from "../../../atom/atoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { currentQuestionState, surveyCompletionState } from "../../../atom/survey/survey-atoms";
import { useNavigate } from "react-router-dom";
import { addUserObj, getUserObj, deleteUserObj, getAllUsers } from "../../../store/pouch/user-pouch";
import { addSurvey, getSurvey } from "../../../store/pouch/survey-pouch";
import { addLevelUp, getLastLevelUp } from "../../../store/pouch/level-up-pouch";
import vxInstance from "../../../api/VxApiService";
import { jwtDecode } from "jwt-decode";
import { getSurveyQuestion } from "../../../store/pouch/survey-questions-pouch";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import MyEnv from "../../../util/MyEnv";
import { getGoogleOAuthUrl } from '../../../util/OAuthGoogleUtil';
import { getQueryStringParams } from '../../../util/QueryStringUtil';
import OtpComponent from "./OtpComponent";
import vxOAuthInstance from "../../../api/VxOAuthService";
import { addOtp, matchOtpByUserEmail, setOtpIsValidFalseByUserEmail } from "../../../store/pouch/otp-pouch";
import { isValid } from "date-fns";
import { IntervalCountdown } from "./interval-countdown";

const SignupLogin = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);
  const [prelogin, setPrelogin] = useRecoilState(preloginState);
  const [currentQuestion, setCurrentQuestion] = useRecoilState(currentQuestionState);
  const [loginEmail, setLoginEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [apiVersion, setApiVersion] = useState(process.env.REACT_APP_ENABLE_API_V1 === "true" ? "v1" : "v0");
  const setSurveyCompletion = useSetRecoilState(surveyCompletionState);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [questionAnswer100, setQuestionAnswer100] = useRecoilState(
    questionAnswerState("100"),
  );
  const [questionAnswer200, setQuestionAnswer200] = useRecoilState(
    questionAnswerState("200"),
  );
  const [questionAnswer300, setQuestionAnswer300] = useRecoilState(
    questionAnswerState("300"),
  );
  const [questionAnswer400, setQuestionAnswer400] = useRecoilState(
    questionAnswerState("400"),
  );

  const [questionAnswer500, setQuestionAnswer500] = useRecoilState(
    questionAnswerState("500"),
  );

  const [questionAnswer501, setQuestionAnswer501] = useRecoilState(
    questionAnswerState("501"),
  );

  const [questionAnswer502, setQuestionAnswer502] = useRecoilState(
    questionAnswerState("502"),
  );

  const [questionAnswer600, setQuestionAnswer600] = useRecoilState(
    questionAnswerState("600"),
  );

  const [questionAnswer700, setQuestionAnswer700] = useRecoilState(
    questionAnswerState("700"),
  );

  const [questionAnswer800, setQuestionAnswer800] = useRecoilState(
    questionAnswerState("800"),
  );

  const [questionAnswer900, setQuestionAnswer900] = useRecoilState(
    questionAnswerState("900"),
  );

  const [questionAnswer1000, setQuestionAnswer1000] = useRecoilState(
    questionAnswerState("1000"),
  );

  const [otp, setOtp] = useState(['', '', '', '']);
  const [otpRequested, setOtpRequested] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('');

  const handleTestLogin = () => {
    const awaitTestLogin = async () => {
      await setUserInfoAndRouteToSurvey("test01@dev.com", "Test User 01");
    };
    awaitTestLogin();
  };

  const forceUpdate = () => {
    setTriggerUpdate(prev => !prev);
  };

  const setUserInfoAndRouteToSurvey = async (email, name, userRoles = [], accessToken = null, refreshToken = null) => {
    try {

      debugger;
      email = email.toLowerCase();
      // let userObj = await getUserObj(email);
      
      const userDataRec = await vxInstance.getData("user/get-user", accessToken, refreshToken);

      const surveyData = await vxInstance.getData("survey/get-survey", accessToken, refreshToken);
      const foundSurvey100 = surveyData?.surveyData?.find(item => item._id === email + "-surveyQNum-100");
      const foundSurvey200 = surveyData?.surveyData?.find(item => item._id === email + "-surveyQNum-200");
      const foundSurvey300 = surveyData?.surveyData?.find(item => item._id === email + "-surveyQNum-300");
      const foundSurvey400 = surveyData?.surveyData?.find(item => item._id === email + "-surveyQNum-400");
      const foundSurvey500 = surveyData?.surveyData?.find(item => item._id === email + "-surveyQNum-500");
      const foundSurvey501 = surveyData?.surveyData?.find(item => item._id === email + "-surveyQNum-501");
      const foundSurvey600 = surveyData?.surveyData?.find(item => item._id === email + "-surveyQNum-600");
      const foundSurvey700 = surveyData?.surveyData?.find(item => item._id === email + "-surveyQNum-700");
      const foundSurvey800 = surveyData?.surveyData?.find(item => item._id === email + "-surveyQNum-800");
      const foundSurvey900 = surveyData?.surveyData?.find(item => item._id === email + "-surveyQNum-900");
      const foundSurvey1000 = surveyData?.surveyData?.find(item => item._id === email + "-surveyQNum-1000");

      let newUserInfo = {
        _id: email,
        email: email,
        userEmail: email,
        userName: name,
        userNickName: null,
        userPhoneCountry: null,
        userPhone: null,
        userRoles: userRoles,
        accessToken: accessToken,
        refreshToken: refreshToken,
      };
      
      if(userDataRec){
        newUserInfo = {...userDataRec, ...newUserInfo};
      } 
      // else if(userObj) {
      //   newUserInfo = {...userObj, ...newUserInfo};
      // }
      
      
      if(foundSurvey100){
        newUserInfo.userNickName = foundSurvey100.surveyAnswerString;
        setQuestionAnswer100(foundSurvey100);
      }else {
        let surveyQnum100 = await getSurveyQuestion(`${email}-surveyQNum-100`);
        if(surveyQnum100 && surveyQnum100.surveyAnswerString) {
          newUserInfo.userNickName = surveyQnum100.surveyAnswerString;
          setQuestionAnswer100(surveyQnum100);
        }
      }


      if(foundSurvey200){
        newUserInfo.userGender = foundSurvey200.surveyAnswerString;
        setQuestionAnswer200(foundSurvey200);
      }else{
        let surveyQNum200 = await getSurveyQuestion(`${email}-surveyQNum-200`);
        if(surveyQNum200 && surveyQNum200.surveyAnswerString) {
          newUserInfo.userGender = surveyQNum200.surveyAnswerString;
          setQuestionAnswer200(surveyQNum200);
        }
      }

      if(foundSurvey300){
        newUserInfo.userAgeGroup = foundSurvey300.surveyAnswerString;
        setQuestionAnswer300(foundSurvey300);
      }else{
        let surveyQNum300 = await getSurveyQuestion(`${email}-surveyQNum-300`);
        if(surveyQNum300 && surveyQNum300.surveyAnswerString) {
          newUserInfo.userAgeGroup = surveyQNum300.surveyAnswerString;
          setQuestionAnswer300(surveyQNum300);
        }
      }

      if(foundSurvey400){
        newUserInfo.userEthnicity = foundSurvey400.surveyAnswerString;
        setQuestionAnswer400(foundSurvey400);
      }else{
        let surveyQNum400 = await getSurveyQuestion(`${email}-surveyQNum-400`);
        if(surveyQNum400 && surveyQNum400.surveyAnswerString) {
          newUserInfo.userEthnicity = surveyQNum400.surveyAnswerString;
          setQuestionAnswer400(surveyQNum400);
        }
      }

      if(foundSurvey500){
        newUserInfo.userPhone = foundSurvey500.surveyAnswerString;
        setQuestionAnswer500(foundSurvey500);
      }else{
        let surveyQNum500 = await getSurveyQuestion(`${email}-surveyQNum-500`);
        if(surveyQNum500 && surveyQNum500.surveyAnswerString) {
          newUserInfo.userPhone = surveyQNum500.surveyAnswerString;
          setQuestionAnswer500(surveyQNum500);
        }
      }
      if(foundSurvey501){
        newUserInfo.userPhoneCountry = foundSurvey501.surveyAnswerString;
        setQuestionAnswer501(foundSurvey501);
      }else{
        let surveyQNum501 = await getSurveyQuestion(`${email}-surveyQNum-501`);
        if(surveyQNum501 && surveyQNum501.surveyAnswerString) {
          newUserInfo.userPhoneCountry = surveyQNum501.surveyAnswerString;
          setQuestionAnswer501(surveyQNum501);
        }
      }
      if(foundSurvey600){
        newUserInfo.userInitialWeightKg = foundSurvey600.surveyAnswerString;
        setQuestionAnswer600(foundSurvey600);
      }else{
        let surveyQNum600 = await getSurveyQuestion(`${email}-surveyQNum-600`);
        if(surveyQNum600 && surveyQNum600.surveyAnswerString) {
          newUserInfo.userInitialWeightKg = surveyQNum600.surveyAnswerString;
          setQuestionAnswer600(surveyQNum600);
        }
      }
      if(foundSurvey700){
        newUserInfo.userHeightCm = foundSurvey700.surveyAnswerString;
        setQuestionAnswer700(foundSurvey700);
      }else{
        let surveyQNum700 = await getSurveyQuestion(`${email}-surveyQNum-700`);
        if(surveyQNum700 && surveyQNum700.surveyAnswerString) {
          newUserInfo.userHeightCm = surveyQNum700.surveyAnswerString;
          setQuestionAnswer700(surveyQNum700);
        }
      }
      if(foundSurvey800){
        newUserInfo.userWaistInch = foundSurvey800.surveyAnswerString;
        setQuestionAnswer800(foundSurvey800);
      }else{
        let surveyQNum800 = await getSurveyQuestion(`${email}-surveyQNum-800`);
        if(surveyQNum800 && surveyQNum800.surveyAnswerString) {
          newUserInfo.userWaistInch = surveyQNum800.surveyAnswerString;
          setQuestionAnswer800(surveyQNum800);
        }
      }
      if(foundSurvey900){
        setQuestionAnswer900(foundSurvey900);
      }else{
        let surveyQNum900 = await getSurveyQuestion(`${email}-surveyQNum-900`);
        if(surveyQNum900 && surveyQNum900.surveyAnswerString) {
          setQuestionAnswer900(surveyQNum900);
        }
      }

      if(foundSurvey1000){
        setQuestionAnswer1000(foundSurvey1000);
      }else{
        let surveyQNum1000 = await getSurveyQuestion(`${email}-surveyQNum-1000`);
        if(surveyQNum1000 && surveyQNum1000.surveyAnswerString) {
          setQuestionAnswer1000(surveyQNum1000);
        }
      }

      let activeUser = { ...newUserInfo, _id: "active" };

      await deleteUserObj("active");
      let { _rev: activeUserRev, ...activeUserWithoutRev } = activeUser; // Destructure to remove _rev
      let { _rev: newUserRev, ...newUserWithoutRev } = newUserInfo; // Destructure to remove _rev

      debugger;
      await addUserObj(activeUserWithoutRev);
      await addUserObj(newUserWithoutRev);

      //let debugUser = await getAllUsers();
      setUser(newUserInfo);

      //get level up
      let levelUpObj = await getLastLevelUp(email);
      if(!levelUpObj) {
        await addLevelUp(activeUser.userEmail, 1, new Date().toISOString());
      }

      let userSurveyProgress = {};
      if(!surveyData?.surveyData?.length){
        userSurveyProgress = {
          currentQuestion: "100",
          isComplete: false,
          completionDateTime: null,
          earnedCoinAmount: 0,
          earnedCoinDatetime: null,
        };
      }else{
        let userSurveyProgress = await getSurvey(email);
        if (!userSurveyProgress) {
          userSurveyProgress = {
            currentQuestion: "100",
            isComplete: false,
            completionDateTime: null,
            earnedCoinAmount: 0,
            earnedCoinDatetime: null,
          };
        }
      }

      debugger;
      if(surveyData?.surveyData?.length === 11){
        setSurveyCompletion(true);
        userSurveyProgress.currentQuestion = "1000";
        userSurveyProgress.isComplete = true;
      }else{
        if(userSurveyProgress.isComplete) {
          //set surveyCompletionState
          setSurveyCompletion(true);
        }
      }

      await addSurvey({ 
        _id: activeUser.userEmail, 
        ...userSurveyProgress, 
        userCreatedDateTime: new Date().toISOString() 
      });

      setCurrentQuestion(userSurveyProgress?.currentQuestion);
      
      // vxInstance.clearUserOtp(accessToken)


      navigate("/");
    } catch (error) {
      console.error("Error in setUserInfoAndRouteToSurvey:", error);
    }
  };

  const handleTestLogin2 = () => {
    navigate("/testcustom");
  };
  const handlePumpData = () => {
    navigate("/research-admin-offline");
  };

  const handleEmailChange = (e) => {
    setLoginEmail(e?.target?.value?.toLowerCase());
    setEmailError("");
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // handleApiLogin();
    }
  };

  const handleLoginWithGmail = () => {
    window.location.href = getGoogleOAuthUrl();
  };

  const handleRequestOtp = () => {
    // Implement your OTP request logic here
    console.log('Requesting OTP for:', loginEmail);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(loginEmail)) {
      setEmailError("Please provide valid email");
      return;
    }
    const awaitRequestOtp = async () => {
      try {
        setOtpRequested(true);
        setPrelogin({
          ...prelogin,
          countDownOTP: 30});
        // let arrayCheckValidOtpByEmail;
        const response = await vxOAuthInstance.requestEmailOtp(loginEmail);
        
      } catch (error) {
        // console.error('Error requesting OTP:', error);
        setPrelogin({
          ...prelogin,
          countDownOTP: 5});
      }
    };
    
      awaitRequestOtp();
    
  };

  const handleCancelOtp = () => {
    setOtpRequested(false);
    setLoginEmail(''); // Clear email input
    setOtp(['', '', '', '']); // Reset OTP input
  };

  const handleSubmitOtp = () => {
    setVerificationStatus("verifying");
    const asyncFn = async () => {
        try {
            // Call email-otp-login here
            const response = await vxOAuthInstance.verifyEmailOtp(loginEmail, otp.join(''));
            console.log("OTP verification response:", response);
            
            //if response is 200, await setuserInfoAndRouteToSurvey()
            if (response?.access_token) {
              const decodedToken = jwtDecode(response.access_token);
              console.log("Decoded token:", decodedToken);
      
              if (decodedToken.roles && decodedToken.roles.includes(apiVersion)) {
                await setUserInfoAndRouteToSurvey(loginEmail, loginEmail, decodedToken.roles, response?.access_token, response?.refresh_token);
                setVerificationStatus("success");
                setPrelogin({
                  ...prelogin,
                  countDownOTP: 0});
              } else {
                console.log(`User does not have the '${apiVersion}' role`);
              }
            } else {
              console.log("No access token received");
              setEmailError("Login failed. Please try again.");
              setVerificationStatus("failure");
            }


        } catch (error) {
            console.error("Error during OTP verification:", error);
            setVerificationStatus("failure");
        }
    };
    asyncFn();
  };

  // Effect to handle OTP submission
  useEffect(() => {
    if (!otp.some(digit => digit === '')) {
      handleSubmitOtp(); // Call handleSubmitOtp if OTP is filled
    }
  }, [otp]); // Dependency on otp

  return (
    <div className="mt-2 flex-col signup-login-container">
      
      {(process.env.REACT_APP_ENABLE_API_V1 === "true") && (
        <div className="api-test-panel border-2 border-dashed border-gray-300 rounded-lg p-4 mt-4">
          <h3 className="text-lg font-semibold mb-4">Use Gmail Login</h3>
          
          <div className="flex justify-center mt-1">
                    <Ripples color="rgba(255, 255, 255, 0.3)">
                      <button
                        onClick={handleLoginWithGmail}
                        className="px-6 py-3 mb-4 font-bold text-white rounded-lg shadow-md bg-gradient-to-r from-red-500 to-red-700 shadow-red-500/15"
                      >
                        <FontAwesomeIcon icon={faGoogle} className="mr-2" />{" "}
                        Login Gmail
                      </button>
                    </Ripples>
                  </div>
        </div>
      )}
      
      {(process.env.REACT_APP_ENABLE_API_V0 === "true" || process.env.REACT_APP_ENABLE_API_V1 === "true") && (
        <>
          <div className="api-test-panel border-2 border-dashed border-gray-300 rounded-lg p-4 mt-4">
            <h3 className="text-lg font-semibold mb-4">Use OTP</h3>
            <div className="mb-4">
              <>
                {otpRequested ? (
                  <>
                    <label className="block mb-2">{loginEmail}</label>
                    <OtpComponent otp={otp} setOtp={setOtp} verificationStatus={verificationStatus} 
                    setVerificationStatus={setVerificationStatus} forceUpdate={forceUpdate} />
                    <button 
                      className="rounded-lg bg-blue-500 text-white px-4 py-2 hover:bg-blue-600 transition-colors duration-300 mr-2"
                      onClick={handleSubmitOtp}
                    >
                      Login/Signup
                    </button>
                    <button 
                      className="rounded-lg bg-red-500 text-white px-4 py-2 hover:bg-red-600 transition-colors duration-300 mt-2"
                      onClick={handleCancelOtp}
                    >
                      Change Email
                    </button>
                  </>
                ) : (
                  <>
                    <label htmlFor="loginEmail" className="block mb-2">Login Email:</label>
                    <input
                      type="email"
                      id="loginEmail"
                      placeholder="Login with your Email"
                      value={loginEmail}
                      onChange={handleEmailChange}
                      onKeyPress={handleKeyPress}
                      className={`w-full p-2 border rounded ${emailError ? 'border-red-500' : 'border-gray-300'}`}
                    />
                    {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
                  </>
                )}
                <button className={`rounded-lg bg-blue-500 text-white px-4 py-2 
                    hover:bg-blue-600 transition-colors duration-300 mt-2 ${
                    prelogin?.countDownOTP ? 'cursor-not-allowed opacity-50' : ''
                  }`}
                  onClick={handleRequestOtp}
                  disabled={prelogin?.countDownOTP}
                >
                  Request OTP {!!prelogin?.countDownOTP && (
                  <IntervalCountdown />
                )}
                </button>
              </>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SignupLogin;