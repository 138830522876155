import React, { useEffect, useState } from "react";
import { faGoogle, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typewriter from "typewriter-effect";
import Ripples from "react-ripples";
import SurveyQuestions from "./SurveyQuestions";
import MyEnv from "../../util/MyEnv";
import { useRecoilValue } from "recoil";
import { userState } from "../../atom/atoms";
import { surveyAtom } from "../../atom/survey/survey-atoms";
import "./Survey.css";
import { useNavigate } from "react-router-dom";

const Survey = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);

  // const surveyState = useRecoilValue(surveyAtom);

  const handleLoginWithGmail = () => {
    window.location.href = MyEnv.RBOLINGOOGLE;
  };

  const handleLoginWithMeta = () => {
    window.location.href = MyEnv.RBOLINMETA;
  };


  return (
    <>
      <div className="flex flex-col relative">
        <div className="flex-grow pb-36">
          <div className="flex justify-center mt-4">
            <div className="text-2xl font-thin text-center text-blue-600">
              <Typewriter
                options={{
                  strings: ["healthChat Survey"],
                  autoStart: true,
                  loop: false,
                  deleteSpeed: Infinity,
                  cursor: "", delay: 50,
                }}
              />
            </div>
          </div>
          {user ? (
            <SurveyQuestions />
          ) : (
            <>
              <div className="flex justify-center mt-4">
                <div className="text-2xl font-thin text-center text-blue-600">
                  <Typewriter
                    options={{
                      strings: ["Let's Login..."],
                      autoStart: true,
                      loop: false,
                      deleteSpeed: Infinity,
                      cursor: "",
                    }}
                  />
                </div>
                
              </div>
              <div className="flex justify-center mt-4">
                <Ripples color="rgba(255, 255, 255, 0.3)">
                  <button
                    onClick={() => navigate('/signup-login')}
                    className="px-6 py-3 font-bold bg-blue-400 shadow-lg shadow-blue-800/35 text-white rounded-lg"
                  >
                    Sign Up / Login
                  </button>
                </Ripples>
              </div>
              {process.env.REACT_APP_ENABLE_ANY_API === "true" && (
                <>
                  {process.env.REACT_APP_ENABLE_META === "true" && (
                    <div className="flex justify-center mt-4">
                      <Ripples color="rgba(255, 255, 255, 0.3)">
                        <button
                          onClick={handleLoginWithMeta}
                          className="px-6 py-3 font-bold text-white rounded-lg shadow-md bg-gradient-to-r from-blue-500 to-blue-700 shadow-blue-500/15"
                        >
                          <FontAwesomeIcon icon={faFacebook} className="mr-2" />{" "}
                          Login Facebook
                        </button>
                      </Ripples>
                    </div>
                  )}
                  <div className="flex justify-center mt-1">
                    <Ripples color="rgba(255, 255, 255, 0.3)">
                      <button
                        onClick={handleLoginWithGmail}
                        className="px-6 py-3 mb-4 font-bold text-white rounded-lg shadow-md bg-gradient-to-r from-red-500 to-red-700 shadow-red-500/15"
                      >
                        <FontAwesomeIcon icon={faGoogle} className="mr-2" />{" "}
                        Login Gmail
                      </button>
                    </Ripples>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className="fixed bottom-0 left-0 right-0 bg-white bg-opacity-90 shadow-md p-2 text-xs text-center text-gray-500">
          By participating this survey, you consent to provide data for
          fulfilment of MSc in Bioinformatics (Research) by Ong Lit Yit (student
          id 24110007) in Perdana Univerity, KL, Malaysia. Your data is
          protected by Malaysian Law and Personal Data Protection Act 2010
          ("PDPA").
        </div>
      </div>
    </>
  );
};

export default Survey;
