// src/component/mission/sub/Daily-01-Weigh.js
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Clock from "react-live-clock";
import CurrencyInput from "react-currency-input-field";
import { nanoid } from "nanoid";
import { dailyAtom } from "../../../atom/daily/daily-atom";
import { userState } from "../../../atom/atoms";
import { weightAtom, updateWeightAtomSelector } from "../../../atom/fatloss/weight-atom";
import { coinEarnedAmountState, coinEarnedAnimationState } from "../../../atom/coin/coin-atoms";
import { coinEarnedTodayState } from "../../../atom/coin/coin-today-atom";
import { upLevelAtom } from "../../../atom/up-level/up-level";
import { addWeightItem, getWeightByUserEmailAndDateTimeString } from "../../../store/pouch/weight-pouch";
import { ClaimCoins, MyCoinHistory } from "../../../util/CoinClaiming";
import { CheckUnclaimedLevelUps } from "../../../util/UpLeveling";
import { getStartOfDayISOStringZ, getEndOfDayISOStringZ, getLocalDateOnly } from "../../../util/Util";
import "./Daily-01-Weigh.css";
import UpLevelMedal from "../UpLevelMedal";
import { addLevelUp } from "../../../store/pouch/level-up-pouch";
import vxInstance from "../../../api/VxApiService"; // Import vxInstance
import useWeighHeightBMIFromSurveyWeighing, { calculateIdealWeight } from '../../../hooks/useWeighHeightBMIFromSurveyWeighing';
import WeightSlider from '../weight/WeightSlider';

const DailyWeigh = () => {
  const [email, setEmail] = useState(null);
  const effectRan = useRef(false);
  const [user, setUser] = useRecoilState(userState);
  const [dailyState, setDailyState] = useRecoilState(dailyAtom);
  const [weightState, setWeightState] = useRecoilState(weightAtom);
  const [apiVersion, setApiVersion] = useState(process.env.REACT_APP_ENABLE_API_V1 ? "v1" : "v0");
  const [isShowError, setIsShowError] = useState(false);
  const { fetchUserWeightHeightBMI } = useWeighHeightBMIFromSurveyWeighing(email, setEmail, apiVersion);

  const { todaysWeight } = dailyState;
  const weightInputRef = useRef(null);
  const navigate = useNavigate();
  const setCoinEarnedAmount = useSetRecoilState(coinEarnedAmountState);
  const setCoinEarnedAnimation = useSetRecoilState(coinEarnedAnimationState);
  const setCoinEarnedToday = useSetRecoilState(coinEarnedTodayState);
  const setWeightAtom = useSetRecoilState(updateWeightAtomSelector);
  const [upLevelState, setUpLevelState] = useRecoilState(upLevelAtom);

  const updateWeightAtom = (userEmail, weightTxDate) => {
    setWeightAtom({
      userEmail,
      weightTxDate,
    });
  };

  const handleWeightChange = (value, name) => {
    
    if (value === undefined || value === "") {
      setDailyState((prevState) => ({
        ...prevState,
        todaysWeight: "",
      }));
    } else {
      const numValue = parseFloat(value);
      if (!isNaN(numValue) && numValue >= 1.99 
      && numValue <= 199.99) {
        setDailyState((prevState) => ({
          ...prevState,
          todaysWeight: value,
        }));
      }
    }
  };

  const saveWeight = async () => {
    const numValue = parseFloat(todaysWeight);
    setIsShowError(false);
    if (!isNaN(numValue) && numValue > 9 && numValue < 200) {
      const todayDate = getLocalDateOnly();
      const todayDateTime = new Date().toISOString();

      const newWeight = {
        _id: `${user?.userEmail}-weight-${todayDateTime}`,
        userEmail: user?.userEmail,
        weightId: nanoid(10),
        weightTxDateTime: todayDateTime,
        weightTxDate: todayDate,
        weightKg: numValue,
      };

      updateWeightAtom(user?.userEmail, todayDate);
      setWeightState((prevState) => ({
        ...prevState,
        weightHistory: [...prevState.weightHistory, newWeight],
      }));
      await addWeightItem(newWeight);

      // Retrieve all weight data for the user
      const allWeightData = await getWeightByUserEmailAndDateTimeString(user?.userEmail, null, null);
      const weightDataArray = allWeightData.map(row => row.doc);

      // Call weightClientToServerUpsert and await its completion
      const { accessToken, refreshToken } = user;
      vxInstance.weightClientToServerUpsert(accessToken, refreshToken, { weightData: weightDataArray });

      let claimCoinsArray = await ClaimCoins();
      let toEarn = claimCoinsArray?.reduce(
        (sum, transaction) => sum + transaction.coinsEarned,
        0,
      );
      setCoinEarnedAmount(toEarn);
      if (toEarn) {
        setCoinEarnedAnimation(true);
        let coinsTodayArray = await MyCoinHistory(
          user?.userEmail,
          getStartOfDayISOStringZ(),
          getEndOfDayISOStringZ(),
        );

        let coinToday = coinsTodayArray?.reduce(
          (sum, transaction) => sum + transaction.coinsEarned,
          0,
        );
        setCoinEarnedToday(coinToday);
      }

      // Check for level up
      const isEligibleForLevelUp = await CheckUnclaimedLevelUps(user?.userEmail, user?.userLevel);
      if (isEligibleForLevelUp) {
        setUpLevelState(prevState => ({
          ...prevState,
          isPlayMedalAnimation: true,
          lastLevelUpDate: new Date().toISOString()
        }));
        // Here you should also update the user's level in your user state/database
        await addLevelUp(`${user?.userEmail}-Level-2`, 2, new Date().toISOString());

        const updatedUser = {
          ...user,
          userLevel: 2,
        };

        setUser(updatedUser);

        vxInstance.userClientToServerUpsert(accessToken, refreshToken, updatedUser);
      }

      setDailyState("");
      console.log("Weight saved:", newWeight);
      navigate("/weight-history");
    } else {
      setIsShowError(true);
    }
  };

  const handleSaveWeight = () => {
    saveWeight();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && todaysWeight) {
      saveWeight();
    }
  };

  const handleHistory = () => {
    console.log("Show weight history");
    navigate("/weight-history");
  };

  const handleBack = () => {
    navigate("/daily");
  };

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;

    const asyncFn = async() => {
      let userWeightHeightInfo = await fetchUserWeightHeightBMI();
      console.log(userWeightHeightInfo?.latestWeight);
      debugger;
      if (userWeightHeightInfo?.latestWeight) {
        // Update only the `todaysWeight` field in `dailyState`
        setDailyState((prevState) => ({
          ...prevState,
          todaysWeight: userWeightHeightInfo?.latestWeight,
        }));
      }
    };
    asyncFn();
  });

  return (
    <div className="daily-weigh">
      <div className="header-container">
        <button onClick={handleBack} className="back-button">
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/daily2/02.1-weight-header.svg`}
        alt="Weighing Scale"
        className="weigh-header"
      />
      <div className="weight-input-container w-full">
        <CurrencyInput
          id="weight-input"
          name="weight-input"
          placeholder="Enter weight (kg)"
          decimalsLimit={2}
          decimalScale={2}
          maxLength={6}
          value={todaysWeight}
          onValueChange={handleWeightChange}
          allowNegativeValue={false}
          decimalSeparator="."
          groupSeparator=""
          ref={weightInputRef}
          className="weight-input "
          onKeyPress={handleKeyPress}
        />

        <WeightSlider
          weight={todaysWeight}
          onWeightChange={handleWeightChange}
        />
        <div className={`text-red-500 ${isShowError ? "visible" : "hidden"}`}>
          Please enter a valid weight.
        </div>
        <div className="text-2xl text-blue-400  ">
          It is now{" "}
          <Clock
            format={"HH:mm:ss"}
            ticking={true}
            timezone={"Asia/Kuala_Lumpur"}
          />
        </div>
      </div>
      <div className="button-container w-3/4">
        <button
          onClick={handleSaveWeight}
          className="weigh-button bg-blue-400 rounded-md shadow-md shadow-blue-800/35 mb-2"
        >
          Save Weight
        </button>
        <button
          onClick={handleHistory}
          className="weigh-button bg-blue-400 rounded-md shadow-md shadow-blue-800/35"
        >
          Weight history
        </button>
      </div>
      <UpLevelMedal />
    </div>
  );
};

export default DailyWeigh;
