// src/component/Home.js
import React, { useEffect, useState, useRef } from "react";
import Typewriter from "typewriter-effect";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  completedStartScreenState,
  stateOfIntroductionState,
  userState,
} from "../atom/atoms";
import { getNavigationItems } from "../store/pouch/navigation-pouch";
import "../input.css";
import "./Home.css";
import RandomGreeting from "./start/RandomGreeting";
import HomeMenu from "./start/menu/HomeMenu";
import { addUserObj, getAllUsers, getUserObj } from "../store/pouch/user-pouch";
import { getQueryStringParams } from '../util/QueryStringUtil'; // Import the utility
import vxOAuthInstance from "../api/VxOAuthService";
import useUserInfoFromSurvey from '../hooks/useInfoFromSurveyVx'; // Import the custom hook
import useWeighHeightBMIFromSurveyWeighing, { calculateIdealWeight } from '../hooks/useWeighHeightBMIFromSurveyWeighing'; // Import the custom hook
import PowerUp from './ideal/PowerUp';
import IdealWeight from './ideal/IdealWeight';
import CurrentWeight from "./ideal/CurrentWeight";

const Home = () => {
  const effectRan = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const completedStartScreen = useRecoilValue(completedStartScreenState);
  const stateOfIntroduction = useRecoilValue(stateOfIntroductionState);
  //apiVersion is useState, read process.env.REACT_APP_ENABLE_API_V1 for "v1" else "v0"
  const [apiVersion, setApiVersion] = useState(process.env.REACT_APP_ENABLE_API_V1 ? "v1" : "v0");
  // State to hold the email
  const [email, setEmail] = useState(null);
  const [latestWeight, setLatestWeight] = useState(0);
  const [idealWeight, setIdealWeight] = useState(0);
  const [needWeight, setNeedWeight] = useState(0);
  const { fetchUserInfo } = useUserInfoFromSurvey(email, setEmail, apiVersion); // Use the custom hook
  const { fetchUserWeightHeightBMI } = useWeighHeightBMIFromSurveyWeighing(email, setEmail, apiVersion);
  const [isCompletedSurvey, setIsCompletedSurvey] = useState(false);
  const [user, setUser] = useRecoilState(userState);

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;
    const setLatestIdealWeight = async () => {
      
      let userWeightHeightInfo = await fetchUserWeightHeightBMI();
      setLatestWeight(userWeightHeightInfo?.latestWeight);
      let tempIdeal = calculateIdealWeight(userWeightHeightInfo?.height);
      setIdealWeight(tempIdeal);
      setNeedWeight(Math.round((tempIdeal - userWeightHeightInfo?.latestWeight) * 100) / 100);
    };

    const processNavigationData = async () => {
      try {
        const url = new URL(window.location.href);
        // Get query parameters
        const queryParams = getQueryStringParams();
        console.log("qp", queryParams); // Access the parameters as needed

        // Example: Accessing access_token
        const state = queryParams?.state?.replaceAll("/", "");
        // Check if state equals to localStorage frontEndState
        let userInfo = null;
        if (state && state === localStorage.getItem("frontEndState")) {
          userInfo = await fetchUserInfo();
          setIsCompletedSurvey(userInfo?.isCompletedSurvey);
          setUser(userInfo);
          await setLatestIdealWeight();
        } else if(user){
          setIsCompletedSurvey(user?.isCompletedSurvey);
          await setLatestIdealWeight();
        } else {
          console.log("state mismatch");
        }

        const usrObj = await getUserObj("active");
        let targetPath = null;

        //let debugUser = await getAllUsers();

        if (!completedStartScreen) {
          targetPath = '/start';
        } else if (!usrObj) {
          targetPath = '/signup-login';
        } else if (!(userInfo?.isCompletedSurvey || user?.isCompletedSurvey)) {
          targetPath = '/survey';
        }
                
        // Clear URL parameters
        const cleanUrl = `${window.location.origin}${window.location.pathname}${window.location.hash}`;
        window.history.replaceState({}, document.title, cleanUrl);

        // Navigate if necessary
        if (targetPath) {
          navigate(targetPath, { 
            replace: true,
            state: { clearSearch: true }
          });
        }

      } catch (error) {
        console.error("Error processing navigation items:", error);
      }
    };
    processNavigationData();
  }, [navigate, completedStartScreen, stateOfIntroduction, fetchUserInfo]);

  const handleNavigateWeighing = () => {
    navigate("/weighing");
  };
  return (
    <>
      <div className="flex flex-col h-screen">
        <>
          <div className="text-xl font-thin text-center text-blue-600 mb-4">
            <div>
              {
                <Typewriter
                  options={{
                    strings: [`Welcome to healthChat`],
                    autoStart: true,
                    loop: false,
                    deleteSpeed: Infinity,
                    pauseFor: 99999,
                    cursor: "",
                  }}
                />
              }
            </div>
          </div>

          <div className="chatbot-image-container2">
            <img
              src={`${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`}
              alt="Chatbot"
              className="chatbot-image"
            />
          </div>
          <RandomGreeting />
          {isCompletedSurvey && ( <div onClick={handleNavigateWeighing}>
            <div className="flex justify-around mt-4 mx-2">
              <div className="w-[49%] mr-1">
                <IdealWeight weight={idealWeight}/>
              </div>
              <div className="w-[49%] ml-1">
                <CurrentWeight need={needWeight} weight={latestWeight}/>
              </div>
            </div>
            {/* <div className="flex justify-around mt-4">
              <div className="w-[49%] ml-1">
                <PowerUp />
              </div>
            </div> */}
          </div>)}
        </>
        <HomeMenu />
      </div>
    </>
  );
};

export default Home;
