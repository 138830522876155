// src/component/mission/weight/WeightSlider.js
import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointUp } from "@fortawesome/free-solid-svg-icons";
import "./WeightSlider.css";

const WeightSlider = ({ weight, onWeightChange }) => {
  const [startX, setStartX] = useState(0);
  const [isIdle, setIsIdle] = useState(false);
  const sliderRef = useRef(null);
  const idleTimer = useRef(null);

  const resetIdleTimer = () => {
    clearTimeout(idleTimer.current);
    setIsIdle(false);
    idleTimer.current = setTimeout(() => setIsIdle(true), 5000); // 3 seconds of idle
  };

  useEffect(() => {
    resetIdleTimer();
    return () => clearTimeout(idleTimer.current); // Cleanup on component unmount
  }, []);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    resetIdleTimer();
  };

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    resetIdleTimer();
  };

  const handleTouchMove = (e) => {
    const currentX = e.touches[0].clientX;
    handleSwipeMovement(currentX);
  };

  const handleMouseMove = (e) => {
    if (e.buttons === 1) {
      const currentX = e.clientX;
      handleSwipeMovement(currentX);
    }
  };

  const handleSwipeMovement = (currentX) => {
    const moveDirection = currentX > startX ? "right" : "left";
    startSwipe(moveDirection);
    if (moveDirection === "right") {
      onWeightChange((parseFloat(weight) + 0.1).toFixed(2));
    } else {
      const newWeight = (parseFloat(weight) - 0.1).toFixed(2);
      if (newWeight >= 0) onWeightChange(newWeight);
    }
  };

  const startSwipe = (direction) => {
    sliderRef.current.classList.add(direction === "right" ? "swipe-right" : "swipe-left");
  };

  const endSwipe = () => {
    sliderRef.current.classList.remove("swipe-right", "swipe-left");
    sliderRef.current.classList.add("reset");

    setTimeout(() => {
      sliderRef.current.classList.remove("reset");
    }, 500);
  };

  return (
    <div
      className="weight-slider"
      ref={sliderRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={endSwipe}
      onMouseLeave={endSwipe}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={endSwipe}
    >
      <button onClick={() => onWeightChange((parseFloat(weight) - 0.1).toFixed(2))} className="decrement-btn">-</button>

      {/* Wavy bars */}
      <div className="wavy-bars">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      <button onClick={() => onWeightChange((parseFloat(weight) + 0.1).toFixed(2))} className="increment-btn">+</button>

      {isIdle && (
        <div className="hand-icon text-blue-800">
          <FontAwesomeIcon icon={faHandPointUp} />
        </div>
      )}
    </div>
  );
};

export default WeightSlider;
