// src/component/ideal/IdealWeight.js
import React from 'react';

const IdealWeight = ({weight}) => {
  return (
    <div className="bg-blue-400 text-white p-4 rounded-lg shadow-md shadow-blue-800/35">
      <h2 className="text-lg font-semibold mb-2">Ideal Weight 
        <span className=' text-green-300'
        style={{
          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)'
        }}>
          <br/>(BMI 23.5)
        </span>
        </h2>
      <p className="text-xl font-bold text-green-300">{weight} kg</p>
    </div>
  );
};

export default IdealWeight;