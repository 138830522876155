import { useRecoilState, useSetRecoilState } from 'recoil';
import { questionAnswerState } from '../atom/survey/survey-questions-atoms';
import { getSurveyQuestion } from '../store/pouch/survey-questions-pouch';
import { addUserObj, getUserObj } from '../store/pouch/user-pouch';
import { addLevelUp, getLastLevelUp } from '../store/pouch/level-up-pouch';
import { userState } from '../atom/atoms';
import { addSurvey, getSurvey } from '../store/pouch/survey-pouch';
import { currentQuestionState, surveyCompletionState } from '../atom/survey/survey-atoms';
import vxOAuthInstance from '../api/VxOAuthService';
import vxInstance from '../api/VxApiService';

const useUserInfoFromSurvey = (email, setEmail, apiVersion = "v0") => {
    const [user, setUser] = useRecoilState(userState);
    const setSurveyCompletion = useSetRecoilState(surveyCompletionState);
    const [currentQuestion, setCurrentQuestion] = useRecoilState(currentQuestionState);
    const [questionAnswer100, setQuestionAnswer100] = useRecoilState(questionAnswerState("100"));
    const [questionAnswer200, setQuestionAnswer200] = useRecoilState(questionAnswerState("200"));
    const [questionAnswer300, setQuestionAnswer300] = useRecoilState(questionAnswerState("300"));
    const [questionAnswer400, setQuestionAnswer400] = useRecoilState(questionAnswerState("400"));
    const [questionAnswer500, setQuestionAnswer500] = useRecoilState(questionAnswerState("500"));
    const [questionAnswer501, setQuestionAnswer501] = useRecoilState(questionAnswerState("501"));
    const [questionAnswer600, setQuestionAnswer600] = useRecoilState(questionAnswerState("600"));
    const [questionAnswer700, setQuestionAnswer700] = useRecoilState(questionAnswerState("700"));
    const [questionAnswer800, setQuestionAnswer800] = useRecoilState(questionAnswerState("800"));
    const [questionAnswer900, setQuestionAnswer900] = useRecoilState(questionAnswerState("900"));
    const [questionAnswer1000, setQuestionAnswer1000] = useRecoilState(questionAnswerState("1000"));

    const fetchUserInfo = async () => {

        const questions = [
            { num: "100", key: "userNickName" },
            { num: "200", key: "userGender" },
            { num: "300", key: "userAgeGroup" },
            { num: "400", key: "userEthnicity" },
            { num: "500", key: "userPhone" },
            { num: "501", key: "userPhoneCountry" },
            { num: "600", key: "userInitialWeightKg" },
            { num: "700", key: "userHeightCm" },
            { num: "800", key: "userWaistInch" },
            { num: "900", key: null },
            { num: "1000", key: null },
        ];
        
        let token = null;
        if(user?.accessToken){
            token = {
                access_token: user?.accessToken,
                refresh_token: user?.refreshToken,
            }
        }else{
            token = await vxOAuthInstance.getTokenByState();
        }
        const surveyData = await vxInstance.getData("survey/get-survey", token?.access_token, token?.refresh_token);
        
        const emailFromToken = token?.email || user?.userEmail;
        const name = token?.userName;

        let userObj = await getUserObj(emailFromToken);
        
        let newUserInfo = {
            _id: emailFromToken,
            email: emailFromToken,
            userEmail: emailFromToken,
            userName: name,
            userNickName: null,
            userPhoneCountry: null,
            userPhone: null,
            userRoles: [apiVersion],
            accessToken: token?.access_token,
            refreshToken: token?.refresh_token,
        };

        if(userObj) {
            newUserInfo = {...userObj, ...newUserInfo};
        }
        
        for (const { num, key } of questions) {
            const foundSurvey = surveyData?.surveyData?.find(item => item._id === emailFromToken + "-surveyQNum-" + num);
            if (key && foundSurvey && foundSurvey.surveyAnswerString) {
                newUserInfo[key] = foundSurvey.surveyAnswerString;
                switch (num) {
                case "100":
                    setQuestionAnswer100(foundSurvey);
                    break;
                case "200":
                    setQuestionAnswer200(foundSurvey);
                    break;
                case "300":
                    setQuestionAnswer300(foundSurvey);
                    break;
                case "400":
                    setQuestionAnswer400(foundSurvey);
                    break;
                case "500":
                    setQuestionAnswer500(foundSurvey);
                    break;
                case "501":
                    setQuestionAnswer501(foundSurvey);
                    break;
                case "600":
                    setQuestionAnswer600(foundSurvey);
                    break;
                case "700":
                    setQuestionAnswer700(foundSurvey);
                    break;
                case "800":
                    setQuestionAnswer800(foundSurvey);
                    break;
                default:
                    break;
                }
            }else{
                switch(num){
                    case "900":
                        setQuestionAnswer900(foundSurvey);
                        break;
                    case "1000":
                        setQuestionAnswer1000(foundSurvey);
                        break;
                }
            }
        }
        setEmail(emailFromToken); // Set the email state

        await addUserObj(newUserInfo);
        // Set the "active" userObj
        let activeUser = { ...newUserInfo, _id: "active" };
        await addUserObj(activeUser);

        //get level up
        let levelUpObj = await getLastLevelUp(emailFromToken);
        if(!levelUpObj) {
            await addLevelUp(activeUser.userEmail, 1, new Date().toISOString());
        }
        
        let userSurveyProgress = await getSurvey(emailFromToken);
        if (!userSurveyProgress) {
            userSurveyProgress = {
                currentQuestion: "100",
                isComplete: false,
                completionDateTime: null,
                earnedCoinAmount: 0,
                earnedCoinDatetime: null,
            };
        }
        
        await addSurvey({ 
            _id: activeUser.userEmail, 
            ...userSurveyProgress, 
            userCreatedDateTime: new Date().toISOString() 
        });
        
        setCurrentQuestion(userSurveyProgress?.currentQuestion);
        userObj = await getUserObj(emailFromToken);
        
        if(surveyData?.surveyData?.length === 11) {
            //set surveyCompletionState
            setSurveyCompletion(true);
            newUserInfo.isCompletedSurvey = true;
            const userObjComplete = {...newUserInfo, isCompletedSurvey: true};
            await addUserObj(userObjComplete);
        }
        setUser(newUserInfo);
        userObj = await getUserObj(emailFromToken);

        
        await vxInstance.userClientToServerUpsert(token?.access_token, 
            token?.refresh_token, userObj);

        return newUserInfo;
    };



    return { fetchUserInfo };
};

export default useUserInfoFromSurvey;